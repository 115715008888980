import type { SyntheticEvent, PropsWithChildren } from "react";
import type { SnackbarProps } from "@mui/material/Snackbar";
import type { AlertProps } from "@mui/material/Alert";
import { createContext, useRef, useContext, useState } from "react";

const AUTOHIDE_DURATION = 5000;

const NoticeTypeContext = createContext<AlertProps["severity"] | null>(null);
const NoticeMessageContext = createContext<SnackbarProps["message"] | null>(
  null
);
const SetNoticeContext = createContext<
  ((t: AlertProps["severity"], m: SnackbarProps["message"]) => void) | null
>(null);

function NoticeProvider({ children }: PropsWithChildren<{}>) {
  const [message, setMessage] = useState<SnackbarProps["message"]>("");
  const [severity, setSeverity] = useState<AlertProps["severity"]>();
  const noticeRef = useRef<NodeJS.Timeout>();

  function resetNotify() {
    clearTimeout(noticeRef.current);
    setMessage("");
    setSeverity(undefined);
  }

  function notify(type: typeof severity, notice: typeof message) {
    resetNotify();

    setTimeout(() => {
      setMessage(notice);
      setSeverity(type);
      noticeRef.current = setTimeout(setMessage, AUTOHIDE_DURATION, "");
    }, 0);
  }

  function handleClose(ev: SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }
    resetNotify();
  }

  const isOpen = Boolean(message);

  return (
    <SetNoticeContext.Provider value={notify}>
      <NoticeMessageContext.Provider value={message}>
        <NoticeTypeContext.Provider value={severity}>
          {!isOpen ? null : (
            <>
              {severity === "error" && (
                <div className="alert-error" onClick={handleClose}>
                  <i className="fa-sharp fa-solid fa-circle-exclamation"></i>{" "}
                  {message}
                </div>
              )}
              {severity === "warning" && (
                <div className="alert-warning" onClick={handleClose}>
                  <i className="fa-sharp fa-solid fa-triangle-exclamation"></i>{" "}
                  {message}
                </div>
              )}
              {severity === "info" && (
                <div className="alert-info" onClick={handleClose}>
                  <i className="fa-sharp fa-solid fa-circle-info"></i> {message}
                </div>
              )}
              {severity === "success" && (
                <div className="alert-success" onClick={handleClose}>
                  <i className="fa-sharp fa-solid fa-circle-check"></i>{" "}
                  {message}
                </div>
              )}
            </>
          )}
          {children}
        </NoticeTypeContext.Provider>
      </NoticeMessageContext.Provider>
    </SetNoticeContext.Provider>
  );
}

function useNotify() {
  const notify = useContext(SetNoticeContext);

  if (!notify) {
    const msg = 'useNotify should only be used in "NoticeProvider\'s" context';
    throw new Error(msg);
  }

  return notify;
}

export { useNotify, NoticeProvider as default };
