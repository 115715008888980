import { Link } from "react-router-dom";

import { useGetInfo } from "../../hooks/useInfo";
import { useGetEnv } from "../../hooks/useEnv";
import { useGetMenuStories } from "../../hooks/useStory";
import SearchProduct from "../../features/SearchProduct";

function Footer() {
  const { data: info } = useGetInfo();
  const menuItems = useGetMenuStories();
  const env = useGetEnv();

  const currentTime = new Date();
  const timeStamp = Math.floor(currentTime.getTime() / 1000); // Round to the nearest second

  function getDayMonthYearFromTimestamp(timestamp: any) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return { day, month, year, time: `${hours}:${minutes}` };
  }

  const { day, month, year, time } = getDayMonthYearFromTimestamp(timeStamp);

  return (
    <>
      <footer className="fade-in-step-3">
        <div className="footer-1">
          <h1 style={{ textTransform: "uppercase" }}>{info.contactUs}</h1>

          <div>
            {info.support}
            <a href="mailto:support@1WA.org" className="link-footer">
              support@1WA.org
            </a>
            <div className="nudge-md"></div>
            Canada + U.S.
            <a href="tel://+18006652575" className="link-footer">
              +1 800.665.2575
            </a>
            <div className="nudge-md"></div>
            Italy
            <a href="tel://+390909023290" className="link-footer">
              +39 090.9023290
            </a>
            <div className="nudge-md"></div>
            International
            <a href="tel://+16042223900" className="link-footer">
              +1 604.222.3900
            </a>
          </div>

          <div className="nudge-lg"></div>
          <Link to="/">
            <img src="img/1wa-icon.png" height="36" alt="1WA Logo" />
          </Link>
        </div>

        <div className="footer-2">
          <h1 style={{ textTransform: "uppercase" }}>{info.aboutUs}</h1>
          {menuItems.data?.map((item) => (
            <div key={item.id}>
              <Link to={`/${item.route}`} className="link-footer-nav">
                {item.content_versions.menuName}
              </Link>
            </div>
          ))}
        </div>

        <div className="footer-3">
          <h1 style={{ textTransform: "uppercase" }}>{info.findProducts}</h1>

          <SearchProduct className="footer-input" />
          <div className="version">
            <strong>{env?.env}</strong> <span>|</span> {env?.version}{" "}
            <span>|</span> {env?.host} <span>|</span> {day}-{month}-{year}{" "}
            {time}
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
